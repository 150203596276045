import React from 'react';
import Logo from './assets/logo.png';
import Logo2x from './assets/logo@2x.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <div class="logo">
        <img src={Logo} width="200" alt="Carza Logo" />
        <p>Coming soon...</p>
      </div>
    </div>
  );
}

export default App;
